import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../../../../../base/util/base.service';
import { environment } from './../../../../../../environments/environment';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class DetalheGerenciarComissaoService  extends BaseService {
  constructor(public http: HttpClient) {
    super(http);
  }

  recuperarDetalhes(idComissao) {
    const url = environment.baseUrl + "Comissao/detalhes/" + idComissao;
    return this.get(url);
  }

  recuperarCategorias(idComissao) {
    const url = environment.baseUrl + "Comissao/detalhes/" + idComissao + "/categorias";
    return this.get(url);
  }

  recuperarItensCategoria(idComissao, idTipoComissaoItem, isAll: boolean, idItemAvulso, actualPage) {
    if (isNullOrUndefined(idTipoComissaoItem)) {
      idTipoComissaoItem = 0;
    }
    const url = environment.baseUrl + "Comissao/detalhes/" + idComissao + "/categorias/" + idTipoComissaoItem + "/itens?isAll=" + isAll + "&idTipoComissaoItem=" + idItemAvulso + "&page=" + actualPage;
    return this.get(url);
  }

  recuperarTodosItensCategoria(idComissao) {
    const url = environment.baseUrl + "Comissao/detalhes/ " + idComissao + "/itens";
    return this.get(url);
  }

  recuperarRelatorioExcel(idComissao) {
    const url = environment.baseUrl + "Comissao/detalhes/ " + idComissao + "/excel";
    return this.getFile(url);
  }
}


