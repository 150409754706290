export class UnidadeColetaVO {
    constructor(
        public id?: number,
        public nome?: string,
        public tipoDocumento?: number,
        public tipoPessoa?: number,
        public inscricaoMunicipal?: string,
        public inscricaoEstadual?: string,
        public tipoUnidadeColeta?: number,
        public cnes?: string,
        public estado?: string,
        public cidade?: string,
        public cep?: string,
        public endereco?: string,
        public numeroEndereco?: string,
        public complementoEndereco?: string,
        public prefixoTelefone?: string,
        public telefone?: string,
        public nomeFantasia?: string,
        public documento?: string,
        // public valorAnaliseCabelo?: number,
        // public valorColetaCabelo?: number,
        // public valorAnaliseUrina?: number,
        // public valorColetaUrina?: number,
        // public valorAnaliseSaliva?: number,
        // public valorColetaSaliva?: number,
        // public valorUnitarioExame?: number,
        public dataValidAlvara?: Date,
        public dataFechamento?: Date,
        public dataValidAutoVistCorpoBombeiro?: Date,
        public nomeStatus?: string,
        public idStatus?: number,
        public emailFiscal?: string,
        public descricaoTipoFinalidadeUnidadeColeta?: string,
        public observacao?: string,
        public razaoSocial?: string,

    ) {}
}
