export class TipoMatrizExameVO {
    constructor(
        public id?: number,
        public descricao?: string,
        public observacao?: string,
        public status?: number,
        public nomeUsuarioCriacao?: string,
        public dataCriacao?: Date[],
        public nomeUsuarioAlteracao?: string,
        public dataAlteracao?: string,
        public nomeUsuarioCancelamento?: string,
        public dataCancelamento?: Date[],
        public nomeStatus?: string
    ) {}
}
