import { PessoaVO } from './pessoa';
import { AmostraVO } from './amostra';

export class FilaProcessamentoVO{
  constructor(
      public id?: number,
      public IdPessoa?: number,
      public tipo?: number,
      public mesCompetencia?: number,
      public anoCompetencia?: number,
      public diaVencimentoInicial?: number,
      public diaVencimentoFinal?: number,
      public dataFechamento?: Date,
      public idTipoCobranca?: number,

      public pessoa?: PessoaVO,
      //public statusFilaProcessamento?: StatusFilaProcessamento,

      //public UsuarioCriacao?: Usuario,
      //public UsuarioAlteracao?: Usuario,
      //public UsuarioCancelamento?: Usuario,
  ){}
}
