import { EtapaMotivoAmostraVO } from "./etapa-motivo-amostra";

export class MotivoAmostraVO {
    constructor(
        public id?: number,
        public idAmostra?: number,
        public idMotivoAmostra?: number,
        public descricaoMotivo?: string,
        public statusMotivo?: number,
        public descricaoStatusMotivo?: string,
        public tipo?: number,
        public descricaoTipo?: string,
        public nomeUsuarioCriacao?: string,
        public dataCriacao?: Date,
        public nomeUsuarioAlteracao?: string,
        public dataAlteracao?: Date,
        public nomeUsuarioCancelamento?: string,
        public dataCancelamento?: Date,
        public EtapaMotivo?: Array<EtapaMotivoAmostraVO>,
        public numberOfRows?: Number,
        public isNovoRegistro?: boolean,
        public tipoMotivo?: number
    ) { isNovoRegistro = false; }
}
