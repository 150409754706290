import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { BaseService } from '../../base/util/base.service';
import { environment } from '../../../environments/environment';


@Injectable()
export class LoginService extends BaseService {

    public idUsuario: number;
    public nomeUsuario: string;

    constructor(public http: HttpClient) {
        super(http);
    }

    enviarLogin(login) {
        return this.post(environment.baseUrl + "usuario/login", login);
    }

    recuperarUsuario(id: number) {
        let urlDestino = environment.baseUrl + "usuario";
        let idUsuario: string;
        if (!isNullOrUndefined(id)) {
            idUsuario = "/" + id;
            urlDestino += idUsuario;
        }
        return this.get(urlDestino);
    }
}
