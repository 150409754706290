import { EnderecoPessoaVO } from "./endereco-pessoa";

export class VendedorVO {
    constructor(
        public id?: number,
        public nome?: string,
        public documento?: string,
        public tipoDocumento?: number, // Enum no back
        public tipoPessoa?: number, // Enum no back
        public descTipoPessoa?: string,
        public rg?: string,
        public rgOrgaoExpedidor?: string,
        public dataEmissaoRG?: Date,
        public nomeFantasia?: string,
        public inscricaoEstadual?: string,
        public inscricaoMunicipal?: string,
        public emailFiscal?: string,
        public enderecos?: Array<EnderecoPessoaVO>,
    ) {}
}
