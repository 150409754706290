import { HistoricoKitColetaVO } from "./historico-kit-coleta";

export class KitColetaVO {
    constructor(
        public dataAlteracao?: Date,
        public dataCancelamento?: Date,
        public dataCriacao?: Date,
        public dataEnvio?: Date,
        public dataValidade?: string,
        public dataSolicitacao?: Date,
        public descricaoMatrizExame?: string,
        public descricaoStatus?: string,
        public descricaoTipoKitColeta?: string,
        public id?: number,
        public idTipoMatrizExame?: number,
        public idUnidadeColeta?: number,
        public idTipoKitColeta?: number,
      
        public intervaloInicialAmostra?: number,
        public nomeUnidadeColeta?: string,
        public nomeUsuarioAlteracao?: string,
        public nomeUsuarioCancelamento?: string,
        public nomeUsuarioCriacao?: string,
        public numberOfRows?: number,
        public quantidade?: number,
        public saldo?: number,
        public siglaAmostra?: string,
        public status?: number,
        public intervaloInicialAmostraFormatado?: string,
        
        public historicoKitColeta?: Array<HistoricoKitColetaVO>,
    ) { }
}
