import { UnidadeColetaArrayGridVO } from "./unidade-coleta-array-grid";

export class TestemunhaVO {
    constructor(
        public id?: number,
        public nome?: string,
        public documento?: string,
        public rg?: string,
        public rgOrgaoExpedidor?: string,
        public dataEmissaoRG?: Date,
        public tipoDocumento?: number, // Enum no back
        public tipoPessoa?: number, // Enum no back
        public nomeMae?: string,
        public nomePai?: string,
        public status?: string,
        public idStatus?: number, // Enum no back
        public listaUnidadeColeta?: Array<UnidadeColetaArrayGridVO>
    ) {}
}
